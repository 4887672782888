import noto_sans_thai from "@/fonts/noto_sans_thai";

import type { FC, PropsWithChildren } from "react";

interface Props {}

export const RootLayout: FC<PropsWithChildren<Props>> = ({ children }) => {
  return <div className={`${noto_sans_thai.variable} ${noto_sans_thai.className}`}>{children}</div>;
};

export default RootLayout;
