import { createContext, useEffect, type FC, type PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import { useLocalStorage, useReadLocalStorage } from "usehooks-ts";

interface Value {
  language: string;
  setLanguage: (language: string) => void;
}

export const TranslationContext = createContext<Value>({
  language: "th",
  setLanguage: () => {},
});

interface Props {}
export const TranslationProvider: FC<PropsWithChildren<Props>> = ({ children }) => {
  const { i18n } = useTranslation();
  const [, setLanguage] = useLocalStorage("language", "th");

  const lsLanguage = useReadLocalStorage<string>("language");

  useEffect(() => {
    if (!i18n.changeLanguage) return;
    if (!lsLanguage) {
      // should only run once on first visit
      setLanguage("th");
    } else if (!["th", "en"].includes(lsLanguage)) {
      // invalid language key, reset to default "th"
      setLanguage("th");
    } else {
      i18n.changeLanguage(lsLanguage as string);
    }
  }, [lsLanguage, i18n, setLanguage]);

  return (
    <TranslationContext.Provider
      value={{
        language: i18n.language,
        setLanguage: setLanguage,
      }}
    >
      {children}
    </TranslationContext.Provider>
  );
};
