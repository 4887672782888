import { DefaultSeo } from "next-seo";
import packageJson from "package.json";
const { version } = packageJson;

export const DefaultSEO = () => {
  const websiteBaseUrl = process.env.NEXT_PUBLIC_WEBSITE_BASE_URL ?? "https://www.dnacanvas.co";
  const title = "DNAcanvas | แพล็ตฟอร์มเพื่อสุขภาพ แบบตรงจุดสุดๆ";
  const description =
    "แพล็ตฟอร์มเพื่อสุขภาพ แบบตรงจุดสุดๆ เพื่อสุขภาพที่ดีกว่า ตรงเป้ากว่า ได้ผลมากกว่า ง่ายดายเพียงไม่กี่ขั้นตอน";
  const imageUrl = `${websiteBaseUrl}/herobanner/.png?v=${version}`;

  return (
    <DefaultSeo
      title={title}
      description={description}
      canonical={websiteBaseUrl}
      openGraph={{
        url: websiteBaseUrl,
        title: title,
        description: description,
        type: "website",
        siteName: "DNAcanvas",
        images: [
          {
            url: imageUrl,
            width: 837,
            height: 838,
            alt: "herobanner",
            type: "image/png",
          },
        ],
      }}
      twitter={{
        handle: "@DNAcanvas",
        site: "@DNAcanvas",
        cardType: "summary_large_image",
      }}
      additionalMetaTags={[
        {
          name: "keywords",
          content: "DNAcanvas, แพล็ตฟอร์มเพื่อสุขภาพ, สุขภาพดี, ได้ผล, ง่าย, ไม่กี่ขั้นตอน",
        },
        {
          name: "viewport",
          content:
            "minimum-scale=1, initial-scale=1, maximum-scale=10, width=device-width, shrink-to-fit=no, user-scalable=yes, viewport-fit=auto",
        },
      ]}
    />
  );
};
