import type { CommonTranslation } from "@/interfaces/common-translation.interface";

export const CommonTranslationEN: CommonTranslation = {
  and: "and",
  via: "via",
  download: "download",
  application: "Application",
  application_abbv: "App",
  baht: "baht",
  from: "from",
  partner: "Partner",
  homepage: "Home",
  "why-dnacanvas": "Why DNAcanvas",
  "about-us": "About us",
  "purchase-products": "Purchase products",
  "purchase-dna-testkit": "Order DNA test kit",
  "privacy-policy": "Privacy policy",
  "terms-of-use": "Terms of use",
  faq: "FAQ",
  age: {
    suffix: "years old",
  },
  "home-heading": {
    title: "Hyper-personalized<br/>Health Solution Platform",
    subtitle: "Smarter, easier and more effective lifestyle & wellness",
    "buy-now": "Personalize now",
  },
  "home-why": {
    title: "Why DNAcanvas?",
    "read-all": "Read all",
    reasons: [
      {
        title: "Semi-personalized food, nutrients and skincare recommendations",
        description:
          "with over 1,000 products from leading partners,  selected to fit your DNA profile and meet all your health goals",
      },
      {
        title: "Pain-free, needle-free, and risk free collection",
        description:
          "Get results equivalent to blood testing simply by having your saliva sample analyzed by the team of technical experts trusted by Thailand’s top hospitals, with quality assurance from the US and Europe",
      },
      {
        title: "Powered by medical-graded DNA testing technology",
        description:
          "High accuracy up to 99.8% by the technical team working with several leading hospitals",
      },
      {
        title: "Actionable insights and recommendations from the experts",
        description:
          "Take care of your health through targeted solutions based on your DNA results covering daily life habits and disease risks",
      },
    ],
  },
  "home-benefit": {
    title: "What you will get {{lineBreak}}from the analysis",
    remark: "Exclusively for Plus Health and Premium packages",
    // remark: "Available only with Plus Health and Premium packages",
    nutrients: "Nutrients you need",
    food: "Food & beverage reaction",
    weight: "Weight management",
    meal: "Meal type",
    beauty: "Beauty insights",
    concern: "Health concerns*",
  },
  "home-steps": {
    title: "4 Steps to DNA Testing",
    subtitle: "", // hidden
    step1: {
      title: "Order the DNAcanvas test kit",
      description:
        "Order the DNAcanvas test kit and provide delivery info through our website, or through LineOA: @DNAcanvas",
    },
    step2: {
      title: "Download the DNAcanvas app",
      description:
        "Upon receiving test kit, register it on the DNAcanvas app (available on App Store and Play Store)",
    },
    step3: {
      title: "Collect DNA sample",
      description:
        "Collect saliva sample into DNA sample tube by following the instructions in the test kit",
    },
    step4: {
      title: "Send your sample to our lab",
      description:
        "Contact us via Line OA to arrange sample delivery. Then, wait for your results in the app within 15 days",
    },
  },
  "home-review": {
    title: "User/Customer Testimonials",
    subtitle: "Why people choose DNAcanvas?",
    reviews: [
      {
        image: "/assets/imgs/webp/reviewers/reviewer1.webp",
        name: "Tukta",
        text: "Test once, for results I can use for the rest of my life - it’s so worth it!",
        occupations: ["$t(occupation.pharmacist)"],
        age: 25,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer2.webp",
        name: "Tigger",
        text: "DNAcanvas helps me manage risks while I’m still healthy. The results are easy to understand and up to standard.",
        occupations: ["$t(occupation.medicalStudent)"],
        age: 26,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer3.webp",
        name: "Pattaraporn",
        text: "It enables life planning for both myself and my loved ones.",
        occupations: ["$t(occupation.teacher)"],
        age: 32,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer4.webp",
        name: "Earn",
        text: "The 99% accuracy has helped me unlock my running potential to be so much better.",
        occupations: ["$t(occupation.academician)", "$t(occupation.angelRunner)"],
        age: 30,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer5.webp",
        name: "Bua Torfun",
        text: "It’s so good and so easy to use that I need to spread the word!",
        occupations: ['$t(occupation.model, {"context": "female"})'],
        age: 25,
      },
    ],
  },
  "home-partner": {
    title: "Partners of DNACanvas",
  },
  "home-quality": {
    title: "Assured product quality",
    subtitle: "Certified for quality and standards by leading institutions worldwide",
    fda: {
      title: "Food and Drug Administration",
      description:
        "We use only FDA-cleared saliva-based DNA collection devices for in vitro diagnostic use.",
    },
    dip: {
      title: "Department of Intellectual Property, Thailand",
      description:
        "Our bio- intelligence analysis unit also own proprietary, race-specific algorithms to create the most precise genetic result to you.",
    },
    bsi: {
      title: "Data security management system",
      description: "Standardized data security and maintenance.",
    },
    "china-export": {
      title: "",
      description: "",
    },
    iso: {
      title: "International Organization for Standardization",
      description: "Ensure standardized management and operations.",
    },
    "european-conformity": {
      title: "European Conformity",
      description:
        "CE marking indicates that  our product has been assessed by the manufacturer and deemed to meet EU safety, health and environmental protection.",
    },
    cap: {
      title: "College of American Pathologists",
      description:
        "Our lab partner has been awarded certification by the Accreditation Board of the College of American Pathologists for giving the finest quality services, ultimately for patients.",
    },
    clia: {
      title: "Clinical Laboratory Improvement Amendments (CLIA)",
      description:
        "The CLIA requires all labs, including the labs used by DNAcanvas, to be inspected for quality control, record keeping and the qualifications of their personnel. The goal is to ensure that the tests are accurate, reliable, timely, confidential and not risky to patients.",
    },
  },
  "home-download": {
    title: "Download application DNAcanvas",
    subtitle: "From App Store and Play Store",
  },
  "about-heading": {
    title: "About us",
    vision:
      "We believe that the next era of self-care and health care is to shift the paradigm from treatment to prevention, from mass to personalized. Our mission is to employ genetic science and technology to help millions of Thais live healthier and happier lives through simple lifestyle modifications that have a lasting positive impact on their overall health and well-being.",
  },
  "about-team": {
    title: "Our team",
  },
  "home-package": {
    // title: "เลือกซื้อชุดตรวจและบริการได้แล้ววันนี้!",
    title: "Get test kit and service now!",
  },
  "why-heading": {
    title: "Why DNAcanvas?",
    cards: [
      {
        title: "Become a better you",
        description:
          "Take care of your health through targeted solutions based on your DNA results, together with our service to match your DNA profile with nutrition, vitamins, and skin care plans through our application",
      },
      {
        title: "Pain-free, needle-free, and risk-free",
        description:
          "Get results equivalent to blood testing simply by having your saliva sample analyzed by the team of technical experts trusted by Thailand’s top hospitals, with quality assurance from the US and Europe",
      },
      {
        title: "One time, for a lifetime",
        description:
          "Test just once, and use your results to modify your health and lifestyle every day",
      },
    ],
  },
  "why-benefit": {
    title: "Covering 6 health matters",
    nutrients: "Nutrients you need",
    food: "Food & beverage reaction",
    weight: "Weight management",
    meal: "Meal type",
    beauty: "Beauty insights",
    concern: "Health concerns*",
  },
  "why-result": {
    title: "Result Analysis Examples",
    remark: "Exclusively for Plus Health and Premium packages",
    // remark: "Available only with Plus Health and Premium packages",
    nutrients: {
      title: "Nutrients you need",
      description: "including recommended vitamins and minerals supplements",
    },
    food: {
      title: "Food & beverage reaction",
      description: "including risks of food allergies, caffeine, alcohol, and more",
    },
    weight: {
      title: "Weight management",
      description:
        "including testing capacities of reducing accumulated fat and any metabolic irregularities",
    },
    meal: {
      title: "Meal type",
      description: "to find out right the meal styles for you",
    },
    beauty: {
      title: "Beauty insights",
      description:
        "including testing for sunlight sensitivity, hair loss, dehydrated skin, and other conditions",
    },
    concern: {
      title: "Health concerns*",
      description:
        "to identify risks to health conditions such as cancer, diabetes, blood pressure, nervous system disorders and drug allergies, as well as a 30-minute online consultation service with an expert medical counsellor",
    },
  },
  "product-heading": {
    title: "Order DNA test kit",
  },
  "product-package": {
    title: "Plan your life precisely with DNA",
    subtitle: "Know quickly and manage risks before anyone",
  },
  package: {
    lifestyle: {
      name: "Lifestyle",
      price: "5900",
      "full-price": "7900",
      description:
        "DNA analysis for over 50 items in 5 lifestyle categories, along with services to match you with the best nutrition, vitamins and skincare plans",
      features: [
        "Meal type",
        "Nutrients you need",
        "Weight management",
        "Beauty insights",
        "Food & Beverage reaction",
      ],
      "button-text": "Get this package",
    },
    plus: {
      name: "Plus health",
      price: "9900",
      "full-price": "12900",
      description:
        "5 Lifestyle categories analysis and 400+ extended health reports on DNA-related disease risks, immunity, allergies and drug response, along with expert medical consultation provided",
      features: [
        "5 analysis categories from the Lifestyle plan",
        "400+ reports on disease risks (i.e. cancer, heart diseases, brain diseases, infectious diseases, and etc.), immunity, allergies and drug response",
        "1 online consultation session with a medical expert",
      ],
      "button-text": "Get this package",
    },
    premium: {
      name: "Premium",
      price: "12900",
      "full-price": "15900",
      // description:
      //   "ผลวิเคราะห์ DNA จากทั้ง Package Lifestyle และ Plus Health พร้อมแพทย์ให้คำปรึกษา พิเศษ! ชุดอาหารเสริมสำหรับ 30 วันมูลค่ากว่า 5,000 บาท",
      description:
        "Extended DNA analysis results from both Lifestyle Package and Plus Health with doctor consultation included. Special! receive a 30-day nutritional supplement package valued at 5,000 baht",
      features: [
        "5 analysis categories from the Lifestyle plan",
        "400+ extended health reports from the Plus Health plan",
        {
          text: "30-day nutritional supplements package worth 5,000 THB delivered straight to your door, based on your DNA results and health goals",
          bullets: [
            "Weight management & fitness",
            "Anti-aging, beauty & skin",
            "Wellness & immunity booster",
          ],
        },
      ],
      "button-text": "Get this package",
    },
    "order-button": "Get this package",
  },
  occupation: {
    pharmacist: "Pharmacist",
    medicalStudent: "Medical student",
    teacher: "Teacher",
    model: "Model",
    model_male: "Model",
    model_female: "Model",
    academician: "Academician",
    angelRunner: "Angel runner",
  },
};
