import { GlobalStyles, css } from "@mui/material";

const styles = css`
  :root {
    --navbar-height: 56px;

    --color-primary: 204 126 114;
    --color-primary-100: 254 245 236;
    --color-primary-600: 204 126 114;
    --color-primary-800: 138 49 51;

    --color-secondary: 254 216 115;
    --color-secondary-300: 254 236 171;
    --color-secondary-400: 254 228 149;
    --color-secondary-500: 254 216 115;
    --color-secondary-600: 218 177 84;

    --color-warning-100: 254 247 220;

    --color-success-100: 242 252 206;

    --color-error: 255 234 217;
    --color-error-100: 255 234 217;

    --color-violet-300: 245 220 251;

    --color-white: 255 255 255;
    --color-black: 68 70 73;
    --color-darkblue: 10 24 40;
  }

  * {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }

  html,
  body {
    max-width: 100vw;
    overflow-x: hidden;
    scroll-behavior: smooth;
    scroll-padding-top: 56px;
  }

  body {
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  *,
  *:before,
  *:after {
    -webkit-overflow-scrolling: touch;
  }

  /* width */
  ::-webkit-scrollbar {
  }

  /* Track */
  ::-webkit-scrollbar-track {
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none; /* Chrome, Safari and Opera */
  }
`;

export const AppGlobalStyles = () => <GlobalStyles styles={styles} />;
