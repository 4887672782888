import { Noto_Sans_Thai } from "next/font/google";

export const noto_sans_thai = Noto_Sans_Thai({
  variable: "--font-noto-sans-thai",
  weight: ["400", "500", "600", "700"],
  style: ["normal"],
  display: "swap",
  subsets: ["thai", "latin"],
  preload: true,
});

export default noto_sans_thai;
