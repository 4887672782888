import type { CommonTranslation } from "@/interfaces/common-translation.interface";

export const CommonTranslationTH: CommonTranslation = {
  and: "และ",
  via: "ผ่านทาง",
  download: "ดาวน์โหลด",
  application: "แอปพลิเคชัน",
  application_abbv: "แอปฯ",
  baht: "บาท",
  from: "จาก",
  partner: "พาร์ทเนอร์",
  homepage: "หน้าแรก",
  "why-dnacanvas": "ทำไมต้อง DNAcanvas",
  "about-us": "เกี่ยวกับเรา",
  "purchase-products": "ซื้อสินค้า",
  "purchase-dna-testkit": "สั่งซื้อชุดตรวจ DNA",
  "privacy-policy": "นโยบายความเป็นส่วนตัว",
  "terms-of-use": "เงื่อนไขการใช้งาน",
  faq: "คำถามที่พบบ่อย",
  age: {
    suffix: "ปี",
  },
  "home-heading": {
    title: "แพลตฟอร์มเพื่อสุขภาพ<br/>แบบตรงจุดสุดๆ",
    subtitle: "เพื่อสุขภาพที่ดีกว่า ตรงเป้ากว่า ได้ผลมากกว่า ง่ายดายเพียงไม่กี่ขั้นตอน",
    "buy-now": "ซื้อเลย",
  },
  "home-why": {
    title: "ทำไมต้อง DNAcanvas",
    "read-all": "อ่านทั้งหมด",
    reasons: [
      {
        title: "บริการจับคู่อาหาร วิตามิน และสกินแคร์เฉพาะคุณ",
        description: `ด้วยสินค้าจากพาร์ทเนอร์ชั้นนำกว่า 1,000 รายการ ที่รอการ<span style="white-space:nowrap">คัดสรร</span>ให้เหมาะกับตัวคุณ ในระดับ DNA ตอบโจทย์ทุกเป้าหมายด้านสุขภาพ`,
      },
      {
        title: "ไม่เจาะ ไม่เจ็บ ไม่เสี่ยง ด้วยการเก็บ DNA แบบน้ำลาย",
        description:
          "เก็บน้ำลายแบบบ้วนด้วยชุดตรวจระดับ Medical-grade ที่ได้ปริมาณ DNA มากที่สุด เทียบเท่าการตรวจเลือด",
      },
      {
        title: "วิเคราะห์ผลอย่างแม่นยำ มาตรฐานเทคโนโลยีอเมริกา",
        description:
          "ความแม่นยำสูงถึง 99.8%\nโดยทีมเทคนิคผู้อยู่เบื้องหลัง\nการวิเคราะห์ในโรงพยาบาลชั้นนำ",
      },
      {
        title: "มั่นใจได้ด้วยคำแนะนำ\nที่ใช้ได้จริงจากผู้เชี่ยวชาญ",
        description:
          "เพื่อให้คุณเข้าใกล้ความสำเร็จในเป้าหมายด้านสุขภาพ และปิดความเสี่ยงด้านที่สุขภาพที่อาจจะเกิดขึ้นกับตัวคุณ",
      },
    ],
  },
  "home-benefit": {
    title: "สิ่งที่จะได้จากการตรวจวิเคราะห์",
    remark: "สำหรับแพ็กเกจ Plus Health และ Premium เท่านั้น",
    // nutrients: "Nutrients you need",
    nutrients: "วิตามินและแร่ธาตุที่ควรเสริม",
    // food: "Food & beverage reaction",
    food: "การตอบสนองต่ออาหารและเครื่องดื่ม",
    // weight: "Weight management",
    weight: "การควบคุมน้ำหนัก",
    // meal: "Meal type",
    meal: "สัดส่วนอาหารที่เหมาะสม",
    // beauty: "Beauty insights",
    beauty: "ผิวพรรณและความงาม",
    // concern: "Health concerns*",
    concern: "ความเสี่ยงโรคต่าง ๆ*",
  },
  "home-steps": {
    title: "4 steps to test DNA",
    subtitle: "ตรวจง่าย ๆ เพียง 4 ขั้นตอน",
    step1: {
      title: "สั่งซื้อชุดตรวจ DNAcanvas",
      description:
        "สั่งซื้อพร้อมแจ้งข้อมูลการจัดส่ง ได้ทางเว็บไซต์ dnacanvas.co หรือ LINE OA: @DNAcanvas ",
    },
    step2: {
      title: "ดาวน์โหลดแอปฯ DNAcanvas",
      description:
        "เมื่อได้รับชุดตรวจให้ลงทะเบียนชุดตรวจเพื่อใช้งานแอปฯ โดยสามารถดาวน์โหลดได้ทั้งบน App Store และ Play Store",
    },
    step3: {
      title: "เก็บตัวอย่าง DNA",
      description:
        "ศึกษาวิธีเก็บตัวอย่าง DNA จากคู่มือที่อยู่ในกล่อง แล้วเก็บน้ำลายในหลอดเก็บตัวอย่าง DNA",
    },
    step4: {
      title: "ส่งตรวจและรอรับผล",
      description:
        "แจ้งเจ้าหน้าที่ทาง LINE OA เพื่อให้ขนส่งเข้าไปรับตัวอย่าง หลังจากนั้นรอรับผลผ่านแอปฯ ภายใน 15 วัน",
    },
  },
  "home-review": {
    title: "ฟังความเห็นจากผู้ใช้จริง",
    subtitle: "ทำไมคนถึงเลือก DNAcanvas",
    reviews: [
      {
        image: "/assets/imgs/webp/reviewers/reviewer1.webp",
        name: "ตุ๊กตา",
        text: "ตรวจครั้งเดียวใช้ผลได้ตลอดชีวิต คุ้มมากๆ",
        occupations: ["$t(occupation.pharmacist)"],
        age: 25,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer2.webp",
        name: "ทิกเกอร์",
        text: "DNAcanvas ช่วยจัดการความเสี่ยง ตั้งแต่ยังสุขภาพดี ผลอ่านเข้าใจง่าย ได้มาตรฐาน",
        occupations: ["$t(occupation.medicalStudent)"],
        age: 26,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer3.webp",
        name: "ภัทราภรณ์",
        text: "วางแผนชีวิตได้ ทั้งตัวเอง และคนที่เรารัก",
        occupations: ["$t(occupation.teacher)"],
        age: 32,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer4.webp",
        name: "เอิร์น",
        text: "แม่นยำมากถึง 99% ช่วยปลดล็อค ศักยภาพการวิ่งของเราให้ดีมากขึ้น",
        occupations: ["$t(occupation.academician)", "$t(occupation.angelRunner)"],
        age: 30,
      },
      {
        image: "/assets/imgs/webp/reviewers/reviewer5.webp",
        name: "บัว ทอฝัน",
        text: "ดีมากจริงๆ จนอยากบอกต่อ ใช้ง่ายมากๆ",
        occupations: ['$t(occupation.model, {"context": "female"})'],
        age: 25,
      },
    ],
  },
  "home-partner": {
    title: "พาร์ทเนอร์ของ DNAcanvas",
  },
  "home-quality": {
    title: "มั่นใจได้ในคุณภาพของผลิตภัณฑ์",
    subtitle: "เพราะได้ผ่านการรับรองคุณภาพและมาตรฐาน{{lineBreak}}จากหลากหลายสถาบันชั้นนำทั่วโลก",
    fda: {
      title: "องค์การอาหารและยาแห่งสหรัฐอเมริกา",
      description: "ผู้ควบคุมตรวจสอบความปลอดภัยของ ผลิตภัณฑ์จึงเป็นที่ยอมรับและเชื่อถือจากทั่วโลก",
    },
    dip: {
      title: "กรมทรัพย์สินทางปัญญา",
      description: "หน่วยงานที่ทำหน้าที่ด้านเครื่องหมายการ ค้าและกฎหมายว่าด้วยเรื่องสิทธิบัตร",
    },
    bsi: {
      title: "ระบบบริหารจัดการความปลอดภัยข้อมูล",
      description: "มาตรฐานการดูแลรักษาความปลอดภัยของข้อมูล และป้องกันการเข้าถึงที่ไม่ได้รับอนุญาต",
    },
    "china-export": {
      title: "มาตรฐานรับรองสินค้าส่งออกของจีน",
      description: "ผลิตภัณฑ์ได้รับการผลิตและผ่านมาตรฐานการ ส่งออกจากประเทศจีน",
    },
    iso: {
      title: "มาตรฐานการวัดคุณภาพองค์กร",
      description: "เพื่อรับรองระบบการบริหารและดำเนินงานขององค์กรแต่ละประเทศให้มาตรฐานทัดเทียมกัน",
    },
    "european-conformity": {
      title: "กฎระเบียบด้านความปลอดภัยในสหภาพยุโรป",
      description:
        "คุณสมบัติถูกต้องและเป็นไปตามข้อกำหนดด้านสุขภาพ ความปลอดภัย และการคุ้มครองสิ่งแวดล้อมตามกฎหมายและกฎระเบียบที่เกี่ยวข้องของสหภาพยุโรป",
    },
    cap: {
      title: "มาตรฐานรับรองห้องปฏิบัติการ",
      description: "ห้องแลปของเรามีมาตรฐานเป็นที่ยอมรับ ได้รับการรับรองคุณภาพและความแม่นยำ",
    },
    clia: {
      title: "มาตรฐานห้องปฏิบัติการทางการแพทย์",
      description:
        "การกำหนดมาตรฐานคุณภาพสำหรับการทดสอบทางห้องปฏิบัติการบนตัวอย่างจากมนุษย์ เช่น เลือด น้ำเหลืองและเนื้อเยื่อ เพื่อวัตถุประสงค์ในการวินิจฉัย ป้องกัน หรือรักษาโรค หรือการประเมินสุขภาพ",
    },
  },
  "home-download": {
    title: "ดาวน์โหลดแอปฯ DNAcanvas",
    subtitle: "ผ่านทาง App Store และ Play Store",
  },
  "about-heading": {
    title: "เกี่ยวกับเรา",
    vision:
      "เราเชื่อว่าการดูแลตัวเองยุคใหม่ต้องเปลี่ยนผ่านจากการรักษาสู่การป้องกัน จากการหว่านแหสู่ความจำเพาะบุคคล พันธกิจของเราคือการนำพันธุศาสตร์และเทคโนโลยีมาปรับใช้เพื่อสุขภาพและความเป็นอยู่ที่ดีขึ้นของคนไทย ผ่านการปรับไลฟ์สไตล์ง่าย ๆ ที่ส่งผลบวกระยะยาวต่อสุขภาพและสุขภาวะองค์รวม",
  },
  "about-team": {
    title: "ทีมของพวกเรา",
  },
  "home-package": {
    title: `เลือกซื้อชุดตรวจและบริการ<span style="white-space:nowrap">ได้แล้ววันนี้!</span>`,
  },
  "why-heading": {
    title: "ทำไมต้อง DNAcanvas?",
    cards: [
      {
        title: "เพื่อเป็นตัวคุณในแบบที่ดีกว่าเดิม",
        description:
          "ด้วยการดูแลสุขภาพแบบตรงจุดจากผลตรวจ DNA พร้อมบริการจับคู่พันธุกรรมของคุณกับเมนูอาหาร วิตามิน และสกินแคร์ผ่านแอปพลิเคชันที่ใช้งานง่าย",
      },
      {
        title: "ไม่เจ็บ ไม่เจาะ ไม่เสี่ยง",
        description:
          "ด้วยการตรวจจากน้ำลาย ได้ผลเทียบเท่ากับการตรวจเลือด ได้รับการรับรองมาตรฐานคุณภาพจากอเมริกาและยุโรป วิเคราะห์ผลโดยทีมผู้เชี่ยวชาญทางเทคนิคทีมเดียวกับโรงพยาบาลชั้นนำ",
      },
      {
        title: "ดูแลสุขภาพได้ด้วยการปรับไลฟ์สไตล์",
        description: "สามารถนำผลไปปรับเปลี่ยนไลฟ์สไตล์และดูแลสุขภาพของคุณให้ดีขึ้นในทุก ๆ วัน",
      },
    ],
  },
  "why-benefit": {
    title: "ครอบคลุมมากถึง 6 เรื่องสุขภาพ",
    // nutrients: "Nutrients you need",
    nutrients: "วิตามินและแร่ธาตุที่ควรเสริม",
    // food: "Food & beverage reaction",
    food: "การตอบสนองต่ออาหารและเครื่องดื่ม",
    // weight: "Weight management",
    weight: "การควบคุมน้ำหนัก",
    // meal: "Meal type",
    meal: "สัดส่วนอาหารที่เหมาะสม",
    // beauty: "Beauty insights",
    beauty: "ผิวพรรณและความงาม",
    // concern: "Health concerns*",
    concern: "ความเสี่ยงโรคต่าง ๆ*",
  },
  "why-result": {
    title: "ตัวอย่างผลการวิเคราะห์",
    remark: "สำหรับแพ็กเกจ Plus Health และ Premium เท่านั้น",
    nutrients: {
      // title: "Nutrients you need",
      title: "วิตามินและแร่ธาตุที่ควรเสริม",
      description: "บอกวิตามินและแร่ธาตุที่ควรเสริม",
    },
    food: {
      // title: "Food & beverage reaction",
      title: "การตอบสนองต่ออาหารและเครื่องดื่ม",
      description: "ตรวจความเสี่ยงต่อการแพ้อาหาร คาเฟอีน\nแอลกอฮอล์ และอื่น ๆ",
    },
    weight: {
      // title: "Weight management",
      title: "การควบคุมน้ำหนัก",
      description: "ตรวจความสามารถในการลดไขมันสะสม\nความยากง่ายของการควบคุมน้ำหนัก",
    },
    meal: {
      // title: "Meal type",
      title: "สัดส่วนอาหารที่เหมาะสม",
      description: "บอกสไตล์อาหารที่เหมาะกับคุณ",
    },
    beauty: {
      // title: "Beauty insights",
      title: "ผิวพรรณและความงาม",
      description: "ตรวจความไวต่อแดด ภาวะผมบาง\nผิวขาดน้ำ และอื่น ๆ",
    },
    concern: {
      // title: "Health concerns*",
      title: "ความเสี่ยงโรคต่าง ๆ*",
      description:
        "บอกความเสี่ยงโรคต่าง ๆ เช่น มะเร็ง เบาหวาน ความดัน ระบบประสาท การแพ้ยาและอื่น ๆ พร้อมบริการปรึกษาแพทย์ออนไลน์ 30 นาที",
    },
  },
  "product-heading": {
    title: "สั่งซื้อชุดตรวจ DNA",
  },
  "product-package": {
    title: "วางแผนชีวิตแม่นยำจาก DNA",
    subtitle: "รู้เร็ว จัดการความเสี่ยงก่อนใคร",
  },
  package: {
    lifestyle: {
      name: "Lifestyle",
      price: "5900",
      "full-price": "7900",
      description:
        "วิเคราะห์ผล DNA กว่า 50 รายการ\nใน 5 หมวดหมู่ด้าน Lifestyle\nพร้อมบริการจับคู่เมนูอาหาร\nวิตามินและสกินแคร์ที่เหมาะกับคุณ",
      features: [
        "สัดส่วนอาหารที่เหมาะสม",
        "วิตามินและแร่ธาตุที่ควรเสริม",
        "การควบคุมน้ำหนัก",
        "ผิวพรรณและความงาม",
        `การตอบสนองต่ออาหาร<span style="white-space:nowrap">และเครื่องดื่ม</span>`,
      ],
      "button-text": "สั่งซื้อแพ็กเกจ",
    },
    plus: {
      name: "Plus health",
      price: "9900",
      "full-price": "12900",
      description:
        "วิเคราะห์ผล DNA กว่า 50 รายการ และรายงานด้านสุขภาพ (ความเสี่ยงโรค ภูมิคุ้มกัน อาการแพ้ และปฏิกิริยาต่อการใช้ยา) กว่า 400 รายการ พร้อมให้คำปรึกษาโดยแพทย์ผู้เชี่ยวชาญ",
      features: [
        "5 หมวดหมู่จากชุด Lifestyle",
        "รายงานด้านสุขภาพกว่า 400 รายการ เช่น รายงานความเสี่ยงโรคมะเร็ง และโรคร้ายแรงต่างๆ (โรคหัวใจและหลอดเลือด โรคระบบประสาทและสมอง) ความเสี่ยงโรคติดเชื้่อ และโรคที่พบบ่อย รายงานเกี่ยวกับภูมิคุ้มกัน อาการแพ้ต่างๆ และปฏิกิริยาต่อการใช้ยารักษา",
        "บริการปรึกษาแพทย์ผู้เชี่ยวชาญออนไลน์ 1 ครั้ง",
      ],
      "button-text": "สั่งซื้อแพ็กเกจ",
    },
    premium: {
      name: "Premium",
      price: "12900",
      "full-price": "15900",
      description:
        "ผลวิเคราะห์ DNA จากทั้ง Package Lifestyle และ Plus Health พร้อมแพทย์ให้คำปรึกษา พิเศษ! ชุดอาหารเสริมสำหรับ 30 วันมูลค่ากว่า 5,000 บาท",
      features: [
        "5 หมวดหมู่จากชุด Lifestyle",
        "รายงานด้านสุขภาพกว่า 400 รายการ เทียบเท่าชุด Plus Health (ความเสี่ยงโรค ภูมิคุ้มกัน อาการแพ้ และปฏิกิริยาต่อการใช้ยารักษา)",
        {
          text: "ชุดอาหารเสริมจากผลตรวจ DNA สำหรับ 30 วันตามเป้าหมายด้านสุขภาพที่คุณเลือกเองมูลค่ากว่า 5,000 บาท จัดส่งถึงบ้านคุณ",
          bullets: [
            "ควบคุมน้ำหนักและดูแลหุ่น",
            "บำรุงผิวพรรณและความงาม",
            "สุขภาพแบบองค์รวมและเสริมสร้างภูมิคุ้มกัน",
          ],
        },
      ],
      "button-text": "สั่งซื้อแพ็กเกจ",
    },
    "order-button": "สั่งซื้อแพ็กเกจ",
  },
  occupation: {
    pharmacist: "เภสัชกร",
    medicalStudent: "นักศึกษาแพทย์",
    teacher: "ครู",
    model: "นายแบบ/นางแบบ",
    model_male: "นายแบบ",
    model_female: "นางแบบ",
    academician: "นักวิชาการ",
    angelRunner: "นางฟ้านักวิ่ง",
  },
};
