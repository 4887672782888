import noto_sans_thai from "@/fonts/noto_sans_thai";
import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      slg: 900,
      lg: 1024,
      xl: 1280,
      "2xl": 1440,
      "3xl": 1920,
    },
  },
  palette: {
    mode: "light",
    primary: {
      main: "rgb(204 126 114)", // TODO: dup color
      100: "rgb(254 245 236)",
      200: "rgb(253 233 218)",
      300: "rgb(249 216 198)",
      500: "rgb(204 126 114)", // TODO: dup color
      600: "rgb(204 126 114)", // checked
      700: "rgb(171 84 78)",
      800: "rgb(138 49 51)",
    },
    secondary: {
      main: "rgb(254 216 115)",
      300: "rgb(254 236 171)",
      400: "rgb(254 228 149)",
      500: "rgb(254 216 115)",
      600: "rgb(218 177 84)",
    },
    tertiary: {
      100: "rgb(252 248 241)",
      200: "rgb(249 237 225)",
      main: "rgb(214 186 164)",
      500: "rgb(214 186 164)",
      600: "rgb(184 144 119)",
    },
    success: {
      main: "rgb(139 208 19)",
      100: "rgb(242 252 206)",
    },
    warning: {
      main: "rgb(254 192 83)",
      500: "rgb(254 192 83)",
      200: "rgb(254 237 186)",
      100: "rgb(254 247 220)",
    },
    error: {
      main: "rgb(255 234 217)",
      100: "rgb(255 234 217)",
    },
    info: {
      main: "rgb(229 240 251)",
      100: "rgb(242 247 253)",
      200: "rgb(229 240 251)",
      300: "rgb(212 226 244)",
    },
    violet: {
      main: "rgb(245 220 251)", // FIXME: I made this up
      300: "rgb(245 220 251)",
      100: "rgba(253 243 254)",
    },
    mint: {
      main: "rgb(79 229 204)",
      500: "rgb(79 229 204)",
      100: "rgba(220 253 235)",
    },
    white: {
      main: "rgb(var(--color-white))",
      contrastText: "rgb(var(--color-black))",
    },
    gray: {
      main: "rgb(164 168 181)",
      100: "rgb(248 248 249)",
      200: "rgb(234 235 238)",
      500: "rgb(164 168 181)",
    },
    black: {
      main: "rgb(var(--color-black))",
      contrastText: "rgb(var(--color-white))",
    },
    common: {
      black: "rgb(var(--color-black))",
      white: "rgb(var(--color-white))",
      darkblue: "rgb(var(--color-darkblue))",
    },
    text: {
      black: "rgb(var(--color-black))",
      white: "rgb(var(--color-white))",
      darkblue: "rgb(var(--color-darkblue))",
    },
    translucent: {
      black: {
        100: "rgb(var(--color-black) / 1)",
        88: "rgb(var(--color-black) / 0.88)",
        56: "rgb(var(--color-black) / 0.56)",
        get: (o: number) => `rgb(var(--color-black) / ${o < 1 ? o : o / 100})`,
      },
    },
    background: {
      default: "rgb(var(--color-white))",
      paper: "rgb(var(--color-white))",
    },
    boxShadow: {
      200: "0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815), 0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519)",
    },
    filter: {
      shadow200:
        "drop-shadow(0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815)) drop-shadow(0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519))",
      shadow400:
        "drop-shadow(0px 38.5185px 25.4815px rgba(0, 0, 0, 0.0425185)) drop-shadow(0px 20px 13px rgba(0, 0, 0, 0.035)) drop-shadow(0px 8.14815px 6.51852px rgba(0, 0, 0, 0.0274815)) drop-shadow(0px 1.85185px 3.14815px rgba(0, 0, 0, 0.0168519))",
    },
    divider: "rgb(234 235 238)",
  },
  typography: {
    fontFamily: noto_sans_thai.style.fontFamily,
    htmlFontSize: 16,
    fontSize: 16,
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
    h1: {},
    h2: {
      fontSize: "28px",
      lineHeight: "36px",
      fontWeight: 700,
    },
    h3: {
      fontSize: "21px",
      lineHeight: "30px",
      fontWeight: 700,
    },
    h4: {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: 700,
    },
    h5: {},
    h6: {},
    subtitle1: {},
    subtitle2: {},
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 400,
    },
    body2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 400,
    },
    body3: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 400,
    },
    caption1: {
      fontSize: "21px",
      lineHeight: "30px",
      fontWeight: 600,
    },
    caption2: {
      fontSize: "18px",
      lineHeight: "26px",
      fontWeight: 600,
    },
    caption3: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 600,
    },
    caption4: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 600,
    },
    caption5: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 600,
    },
    label1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    label2: {
      fontSize: "14px",
      lineHeight: "22px",
      fontWeight: 700,
    },
    label3: {
      fontSize: "12px",
      lineHeight: "18px",
      fontWeight: 700,
    },
    button: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
      textTransform: "initial",
    },
  },
  shape: {
    borderRadius: 1,
  },
  mixins: {
    toolbar: {
      height: 56,
    },
  },
  components: {
    MuiAppBar: {
      defaultProps: {},
      styleOverrides: {
        root: {
          boxShadow: "unset",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: () => ({
          boxShadow: "unset",
          minWidth: "unset",
          textTransform: "initial",
        }),
        sizeMedium: () => ({
          height: 48,
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ theme }) => ({
          padding: theme.spacing(0.5, 1),
        }),
        label: () => ({
          padding: 0,
        }),
      },
    },
    MuiContainer: {
      defaultProps: {
        maxWidth: "2xl",
        disableGutters: false,
      },
      styleOverrides: {
        root: ({ theme }) => ({
          "&:not(.MuiContainer-disableGutters)": {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            [theme.breakpoints.up("sm")]: {
              paddingLeft: theme.spacing(3),
              paddingRight: theme.spacing(3),
            },
            [theme.breakpoints.up("md")]: {
              paddingLeft: theme.spacing(4),
              paddingRight: theme.spacing(4),
            },
            [theme.breakpoints.up("lg")]: {
              paddingLeft: theme.spacing(5),
              paddingRight: theme.spacing(5),
            },
            [theme.breakpoints.up("xl")]: {
              paddingLeft: theme.spacing(6),
              paddingRight: theme.spacing(6),
            },
            [theme.breakpoints.up("2xl")]: {
              paddingLeft: theme.spacing(7),
              paddingRight: theme.spacing(7),
            },
          },
        }),
        // maxWidthXs: ({ theme }) => ({
        //   paddingLeft: theme.spacing(2),
        //   paddingRight: theme.spacing(2),
        // }),
        // maxWidthSm: ({ theme }) => ({
        //   paddingLeft: theme.spacing(3),
        //   paddingRight: theme.spacing(3),
        // }),
        // maxWidthMd: ({ theme }) => ({
        //   paddingLeft: theme.spacing(4),
        //   paddingRight: theme.spacing(4),
        // }),
        // maxWidthLg: ({ theme }) => ({
        //   paddingLeft: theme.spacing(5),
        //   paddingRight: theme.spacing(5),
        // }),
        // maxWidthXl: ({ theme }) => ({
        //   paddingLeft: theme.spacing(6),
        //   paddingRight: theme.spacing(6),
        // }),
        // // @ts-ignore
        // maxWidth2xl: ({ theme }) => ({
        //   paddingLeft: theme.spacing(7),
        //   paddingRight: theme.spacing(7),
        // }),
        disableGutters: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiLink: {
      defaultProps: {
        underline: "hover",
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "p",
          caption1: "p",
          caption2: "p",
          caption3: "p",
          caption4: "p",
          caption5: "p",
        },
        color: "text.black",
      },
      styleOverrides: {
        root: () => ({
          '&[data-text-strike="diagonal"]': {
            width: "fit-content",
            position: "relative",
            "::before": {
              content: '""',
              position: "absolute",
              width: "100%",
              height: "75%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundImage:
                "linear-gradient(to bottom right,transparent 0% 47%,currentColor 49% 51%,transparent 53% 100%)",
            },
          },
        }),
      },
    },
    MuiList: {
      defaultProps: {
        disablePadding: true,
      },
    },
    MuiListItem: {
      defaultProps: {
        disableGutters: true,
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: ({ theme }) => ({
          minWidth: "unset",
          marginRight: theme.spacing(1.25),
        }),
      },
    },
  },
});

export default theme;

// h2: ["28px", { lineHeight: "36px", fontWeight: "700" }],
// h4: ["18px", { lineHeight: "26px", fontWeight: "700" }],
// body1: ["16px", { lineHeight: "24px", fontWeight: "400" }],
// body2: ["14px", { lineHeight: "22px", fontWeight: "700" }],
// body3: ["12px", { lineHeight: "18px", fontWeight: "700" }],
// caption3: ["16px", { lineHeight: "24px", fontWeight: "600" }],
// caption4: ["14px", { lineHeight: "20px", fontWeight: "600" }],
// label1: ["16px", { lineHeight: "24px", fontWeight: "700" }],
// label2: ["14px", { lineHeight: "20px", fontWeight: "700" }],
// --color-primary: 204 126 114;
// --color-primary-100: 254 245 236;
// --color-primary-600: 204 126 114;
// --color-primary-800: 138 49 51;

// --color-secondary: 254 216 115;
// --color-secondary-300: 254 236 171;
// --color-secondary-400: 254 228 149;
// --color-secondary-500: 254 216 115;
// --color-secondary-600: 218 177 84;

// --color-warning-100: 254 247 220;

// --color-success-100: 242 252 206;

// --color-error: 255 234 217;
// --color-error-100: 255 234 217;

// --color-violet-300: 245 220 251;

// --color-white: 255 255 255;
// --color-black: 68 70 73;
// --color-darkblue: 10 24 40;
