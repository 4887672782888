import RootLayout from "@/layouts/RootLayout";
import { CacheProvider } from "@emotion/react";
import { CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import i18n from "i18next";
import { Fragment } from "react";
import ReactGA from "react-ga4";
import { initReactI18next } from "react-i18next";

import type { EmotionCache } from "@emotion/react";
import type { AppProps } from "next/app";

import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

import { DefaultSEO } from "@/components/HTMLHeaders/DefaultSEO";
import { CommonTranslationEN } from "@/locales/en/common-en";
import { CommonTranslationTH } from "@/locales/th/common-th";
import { TranslationProvider } from "@/providers/TranslationProvider";
import { AppGlobalStyles } from "@/styles/GlobalStyles";
import theme from "@/themes/theme";
import createEmotionCache from "@/utils/emotion/createEmotionCache";

if (typeof window !== "undefined") {
  if (process.env.NEXT_PUBLIC_GA) {
    ReactGA.initialize(process.env.NEXT_PUBLIC_GA);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }
}

const clientSideEmotionCache = createEmotionCache();
export interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

i18n.use(initReactI18next).init({
  supportedLngs: ["en", "th"],
  preload: ["en", "th"],
  lng: "th",
  resources: {
    en: {
      translation: CommonTranslationEN,
    },
    th: {
      translation: CommonTranslationTH,
    },
  },
  fallbackLng: false,
});

function App({ Component, pageProps, emotionCache = clientSideEmotionCache }: MyAppProps) {
  return (
    <Fragment>
      <DefaultSEO />
      <AppGlobalStyles />
      <CacheProvider value={emotionCache}>
        <MuiThemeProvider theme={theme}>
          <CssBaseline />
          <TranslationProvider>
            <RootLayout>
              <Component {...pageProps} />
            </RootLayout>
          </TranslationProvider>
        </MuiThemeProvider>
      </CacheProvider>
    </Fragment>
  );
}

export default App;
